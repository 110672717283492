import { Link } from "gatsby"
/* eslint-disable */
import React from "react"
import styled from "styled-components"

import useCurrentUser from "src/hooks/useCurrentUser"
import { ButtonBlue, ButtonWhite } from "src/components/StyledButton"
import config from "src/config"

const Label = styled.span`
  display: none;
  @media only screen and (min-width: 992) {
    display: inline;
  }
`

const ButtonTransparent = styled(ButtonWhite)`
  background-color: transparent;
  color: #0f1722;
  border: 1.5px solid rgba(103, 107, 135, 0.55);
  min-width: 110px;
  transition: all 0.3s ease-out;
  @media (max-width: 790px) {
    min-width: unset;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.15);
    border-color: #777b9b;
  }
`

const logoutRedirectHome = () =>
  window.location.pathname !== "/"
    ? (window.location.href = "/login")
    : window.location.reload()

const LoginButton = ({ label }: any) => {
  const { user, logout } = useCurrentUser()
  let pathname = ""

  if (typeof window !== `undefined`) {
    pathname = window.location.pathname
  }

  return (
    <>
      {user ? (
        user.active && pathname === "/plans" ? (
          <span>
            <ButtonTransparent
              onClick={async () => {
                await logout()
                window.location.reload()
              }}
            >
              Logout
            </ButtonTransparent>
            <a href={`${config.HANDBOOK_HOST}/handbooks?to=dashboard`}>
              <ButtonBlue>Go to dashboard</ButtonBlue>
            </a>
          </span>
        ) : (
          <span>
            <ButtonTransparent
              className="ml-4"
              onClick={async () => {
                await logout()
                logoutRedirectHome()
              }}
            >
              Logout
            </ButtonTransparent>
          </span>
        )
      ) : (
        <div className="hidden md:block" style={{lineHeight: "14px"}}>
          {label && <Label>Already have an account? </Label>}
          <span className="dividing-line">|</span>
          <Link
            to="/login"
            className="font-bold text-black text-14/16 no-underline leading-normal"
          >
            Login
          </Link>
        </div>
      )}
    </>
  )
}

export default LoginButton
