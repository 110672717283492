import React, { useState, useEffect } from "react"
import Select from "react-select"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import tw from "tailwind.macro"
import { FaBars } from "react-icons/fa" //black menuIcon
import { IoMdClose } from "react-icons/io"
import { FaBook } from "react-icons/fa"
import CookieConsent from "react-cookie-consent"
import { css } from "@emotion/core"
import { RiBookOpenLine, RiInformationLine } from "react-icons/ri"
import { FiHelpCircle } from "react-icons/fi"

import { getWindowPath } from "src/utils/windowPath"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import useCurrentUser from "src/hooks/useCurrentUser"
import { switchPage } from "src/utils/navigateTo"
import Button from "src/components/button.js"
import config from "src/config"
import airmasonLogoDark from "src/images/logos/airmason_logo_dark.svg"
import { BookDemoButton, ButtonTransparent } from "src/componentsV2/ui/Button"

import LoginButton from "./LoginButton"
import ContactSalesModal from "src/componentsV2/ContactSalesModal"
import { openNewTab } from "src/utils/openNewTab"

const Enterprise = "/enterprise-grade-employee-handbook-builder"

export const Logo = () => (
  <img src={airmasonLogoDark} alt="AirMason Logo" width="160" height="32" />
)

const ButtonBlue = styled(Button)`
  white-space: nowrap;
  font-weight: bold;
  background: #1283ff;
  color: white;
  margin-left: 15px;
  padding: 10px 25px;
`

const TextSignUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  a {
    color: #1283ff;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    margin-left: 30px;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }

  &:hover a {
    color: #0060cc;
  }

  @media (min-width: 768px) {
    padding: 0;
    width: auto;
    a {
      font-size: 14px;
    }
  }
`

const UserNav = (props: UserCTAProps) => {
  const pathname = getWindowPath()

  return (
    <NavCTA>
      <UserCTA {...props} />
      {pathname !== Enterprise && <LoginButton isHomePage />}
    </NavCTA>
  )
}

const MenuText = styled.div`
  color: #0f1722;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  transition: all 0.3s ease-out;
  padding-left: 1.5rem;
  width: 100%;
  &:hover {
    color: #fb8b42;
  }
  @media (min-width: 768px) {
    padding-left: 0;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    width: auto;
  }
`

const NavMenu = styled.div<{ isOpen?: any }>`
  ${tw`flex w-full ml-0 items-center`}
  top: 0;
  z-index: 100000;
  ${props =>
    props.isOpen
      ? tw`flex-col items-center items-start absolute bg-white inset-0 h-screen pt-5`
      : tw`hidden`}
  a {
    padding: 9px 0px;
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    ${tw`flex justify-start flex-row pt-0`}

    width: auto;

    a {
      ${tw`mr-5`}
    }
  }

  @media (min-width: 1024px) {
    a {
      ${tw`mr-10`}
    }
  }
`

const NavCTA = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  ${tw`flex items-center justify-around`}
  background: #eff7ff;
  height: 55px;

  .dividing-line {
    margin: 0 18px;
    color: #979797;
  }

  .btn-free-trial {
    height: 35px;
    font-size: 14px;
    border: 1px solid rgba(15, 23, 34, 0.25);
    color: #117bf3;
  }

  button {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    background: unset;
    width: auto;
    position: unset;
    .btn-free-trial {
      display: none;
    }
  }
`

interface UserCTAProps {
  openContactSalesModal: Function
}

const UserCTA = (_props: UserCTAProps) => {
  const { user } = useCurrentUser()

  if (user && user.active) {
    return (
      <a href={`${config.HANDBOOK_HOST}/handbooks?to=dashboard`}>
        <ButtonBlue>Go to dashboard</ButtonBlue>
      </a>
    )
  }

  if (user) {
    return (
      <Link to="/plans">
        <ButtonBlue>Continue</ButtonBlue>
      </Link>
    )
  }

  return (
    <TextSignUp>
      <BookDemoButton
        className="md:hidden flex"
        id="request-info-button"
        onClick={() => openNewTab(config.CALENDLY.ENTERPRISE)}
        css={css`
          font-size: 14px;
        `}
        title="Book a demo"
      />
      <ButtonTransparent className="btn-free-trial" onClick={switchPage.plans}>
        Free Trial
      </ButtonTransparent>
      <Link to="/plans" className="hidden md:block m-0">
        Start trial
      </Link>
    </TextSignUp>
  )
}

const AirmasonNav = ({ isOpen, setIsOpen }: any) => {
  const { user, logout } = useCurrentUser()

  const resourceOptions = [
    { value: "resources", label: "Resources", className: "font-bold text-6" },
    {
      value: "faq",
      label: "FAQ",
      url: "/#faq",
      Icon: FiHelpCircle,
      color: "#45888B",
      className: "cursor-pointer",
    },
    {
      value: "policyFinder",
      label: "Handbook policy helper",
      url: "/policies-finder",
      Icon: FaBook,
      color: "#ec9252",
      className: "cursor-pointer",
    },
    {
      value: "helpCenter",
      label: "Help center",
      url: config.SUPPORT_CENTER_URL,
      Icon: RiBookOpenLine,
      color: "#EB0029",
      className: "cursor-pointer",
    },
    {
      value: "about",
      label: "About AirMason",
      url: "/about",
      Icon: RiInformationLine,
      color: "#1283FF",
      className: "cursor-pointer",
    },
  ]

  return (
    <NavMenu isOpen={isOpen}>
      <IoMdClose
        size={25}
        className="text-blue md:hidden cursor-pointer ml-5 mb-1"
        onClick={() => setIsOpen(false)}
      />
      <Link
        to="/"
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>Home</MenuText>
      </Link>
      <Link
        to="/enterprise-grade-employee-handbook-builder"
        style={{ textDecoration: "none" }}
        className="md:w-auto w-full"
      >
        <MenuText>Employee Handbook Builder</MenuText>
      </Link>
      <Link
        to="/automated-policy-updates"
        style={{ textDecoration: "none" }}
        className="md:w-auto w-full"
      >
        <MenuText>Automated Policy Updates</MenuText>
      </Link>
      <Link
        to="/plans"
        style={{ textDecoration: "none" }}
        className="md:w-auto w-full"
      >
        <MenuText>Pricing</MenuText>
      </Link>
      <Link
        to="/about"
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>About</MenuText>
      </Link>
      <Link
        to="/data-processing"
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>Data Processing</MenuText>
      </Link>
      <Link
        to="/advocacyterms"
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>Advocacy & Referral</MenuText>
      </Link>
      <a
        href={config.SUPPORT_CENTER_URL}
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>Help Center</MenuText>
      </a>
      <Select
        onChange={(option: any) => {
          option.url && navigate(option.url)
        }}
        className="hidden md:block"
        getOptionLabel={(props: any) => {
          const { Icon, label, color, className } = props
          return ((
            <div className="flex items-center gap-2">
              {Icon && color && (
                <Icon
                  color={color}
                  size={25}
                  style={{ fontWeight: "700", marginRight: "20px" }}
                />
              )}
              <span className={`${className}`}>{label}</span>
            </div>
          ) as unknown) as string
        }}
        value={{ value: "placeholder", label: "Resources" }}
        options={resourceOptions}
        isSearchable={false}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer",
            minHeight: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            fontSize: "18px",
            lineHeight: 1,
            padding: "9px 0",
          }),
          valueContainer: baseStyles => ({
            ...baseStyles,
            paddingRight: "5px",
          }),
          indicatorSeparator: baseStyles => ({
            ...baseStyles,
            display: "none",
          }),
          input: baseStyles => ({
            ...baseStyles,
            margin: 0,
            padding: 0,
          }),
          dropdownIndicator: baseStyles => ({
            ...baseStyles,
            padding: "0 8px",
          }),
          menu: baseStyles => ({
            ...baseStyles,
            width: "333px",
          }),
          menuList: baseStyles => ({
            ...baseStyles,
            paddingTop: "30px",
            paddingBottom: "14px",
          }),
          option: () => ({
            display: "block",
            userSelect: "none",
            padding: "8px 12px 0 55px",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            marginBottom: "17px",
            fontSize: "18px",
            color: "inherit",
          }),
        }}
      />

      <a
        href="https://blog.airmason.com/"
        className="md:hidden md:w-auto w-full"
        style={{ textDecoration: "none" }}
      >
        <MenuText>Blog</MenuText>
      </a>

      <Link
        to="/security"
        style={{ textDecoration: "none" }}
        className="md:hidden md:w-auto w-full"
      >
        <MenuText>Security</MenuText>
      </Link>

      <div
        className="md:hidden bg-gray-2 w-full text-center pt-8 pb-6 mt-4"
        css={css`
          height: calc(100vh - 273px);
        `}
      >
        <ButtonTransparent
          style={{ height: 35, width: 180, fontSize: "14px" }}
          onClick={switchPage.plans}
          className="mx-auto justify-center mb-5 text-sm"
        >
          Start Free Trial
        </ButtonTransparent>

        {user ? (
          <ButtonTransparent
            style={{ height: 35, width: 180, fontSize: "14px" }}
            className="mx-auto justify-center mb-6 text-sm"
            onClick={async () => {
              await logout()
              window.location.reload()
            }}
          >
            Logout
          </ButtonTransparent>
        ) : (
          <ButtonTransparent
            style={{ height: 35, width: 180 }}
            className="mx-auto justify-center mb-6"
            onClick={switchPage.login}
          >
            Login
          </ButtonTransparent>
        )}

        <BookDemoButton
          onClick={() => switchPage.bookDemo()}
          style={{ height: 35, width: 180 }}
          className="mx-auto"
        />

        <p className="text-blue leading-none mt-6 text-sm">+1 (646) 918-8673</p>
        <div className="leading-none mt-4 text-13/16">
          <Link
            to="/terms-and-conditions"
            className="text-black opacity-50 underline mr-5"
          >
            T & C’s
          </Link>
          <Link className="text-black underline opacity-50" to="/privacy">
            Privacy
          </Link>
        </div>
      </div>
    </NavMenu>
  )
}

const HeaderNav = styled.div`
  ${tw`w-full block flex-grow flex items-center`}
 
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    ${tw`justify-around flex-row w-auto`}
  }

  @media (min-width: 1024px) {
    ${tw`justify-between px-4`}
  }
`
const HambugerButtonWrapper = styled.div`
  ${tw`block md:hidden lg:hidden`}
`

const HeaderWraper = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 90px;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    justify-content: center;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    height: auto;
    padding: 18px 0px;
  }
`

const ContainerPageSticky = styled(ContainerPage)`
  background-color: #f5f8fb;
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 999;
  width: 100%;

  #request-info-button {
    user-select: none;
    transition: all 0.4s;
  }

  &:not(.sticky) {
    @media (min-width: 768px) {
      #request-info-button {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`

const Header = (props: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const [stickyClass, setStickyClass] = useState("")
  const [isContactSalesOpenModal, setIsContactSalesOpenModal] = useState(false)
  const { user } = useCurrentUser()

  function openContactSalesModal(isOpen: boolean): void {
    setIsContactSalesOpenModal(isOpen)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function handleScroll(): void {
    if (window.scrollY > 0) {
      setStickyClass("sticky")
    } else {
      setStickyClass("")
    }
  }

  return (
    <>
      <ContainerPageSticky
        className={stickyClass}
        data-loadable-component="header-landing-page"
      >
        <CookieConsent
          disableStyles={true}
          location="top"
          buttonText="Accept"
          cookieName="airmason-acookie-consent"
          expires={150}
          buttonClasses="btn-accept"
          contentClasses="cookie-consent-content"
        >
          This site uses
          <a
            target="_blank"
            href="https://en.wikipedia.org/wiki/HTTP_cookie"
            style={{ color: "white", marginLeft: "5px" }}
          >
            cookies
          </a>
          . Is that okay?
        </CookieConsent>
        <WrapperPage isLoggedIn={user}>
          <HeaderWraper>
            <div className="w-full text-center md:w-full xl:w-auto flex items-center mr-0 md:mr-10 md:px-0 px-15/16">
              <HambugerButtonWrapper>
                <button className="appearence-none focus:outline-none">
                  <FaBars onClick={() => setIsOpen(!isOpen)} size={20} />
                </button>
              </HambugerButtonWrapper>
              <Link to="/" className="w-full">
                <Logo />
              </Link>
            </div>
            <HeaderNav>
              <AirmasonNav isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
              <UserNav openContactSalesModal={openContactSalesModal} />
            </HeaderNav>
          </HeaderWraper>
        </WrapperPage>
      </ContainerPageSticky>
      <React.Fragment>
        {isContactSalesOpenModal ? (
          <ContactSalesModal
            onClose={() => openContactSalesModal(false)}
            isOpen={isContactSalesOpenModal}
          />
        ) : null}
      </React.Fragment>
    </>
  )
}

export default Header
